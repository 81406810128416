import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        selectedApp: 0,
        userType: 0,
        fullName: null,
        showMenu: false,
        UserId: null,
        UserData: null,

    },
    getters: {
        getUserId(state) {
            return state.UserId;
        },
        getUserData(state) {
            if (localStorage.getItem("user") == "undefined" || localStorage.getItem("user") == null) {
                return null;
            }
            state.UserData = JSON.parse(localStorage.getItem("user"))
            return state.UserData
        }

    },
    mutations: {
        mutateUserId(state, value) {
            state.UserId = value
        }
    },
    actions: {
        mutateUserIdEvent({ commit }, value) {
            commit('mutateUserId', value)
        }
    },
    modules: {
    }
})
