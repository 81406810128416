import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import locale from 'element-ui/lib/locale/lang/en';
import BlockUIService from './Shared/BlockUIService.js';
import VueHtml2pdf from 'vue-html2pdf';
import axios from 'axios';
import store from './Shared/store.js';
import { routes } from './routers/routes.js';
import DataService from './Shared/DataService.js';
import Layout from './components/Layout/Layout.vue';
import Login from './components/Login/Login.vue';
import NotFound from './components/Errors/NotFound.vue';


axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

Vue.use(VueHtml2pdf);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;

export const eventBus = new Vue();

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    linkActiveClass: 'active',
    routes: [
        {
            path: '/Security/Login',
            component: Login,
            meta: { requiresAuth: false }
        },
        {
            path: '/',
            component: App,
            children: [
                {
                    path: '',
                    component: Layout,
                    children: routes
                },
            ]
        },
        {
            path: '*',
            component: NotFound
        }
    ]
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiredUserType = to.meta.requiredUserType;
    const isAuthenticated = !!localStorage.getItem('token');
    const userData = store.getters.getUserData;

    const isUserTypeAllowed = () => {
        if (!requiredUserType) {
            return true; // No specific user type required
        }
        if (Array.isArray(requiredUserType)) {
            return requiredUserType.includes(userData.userType); // Check if user type is in the array
        }
        return userData.userType === requiredUserType; // Check if user type matches the required type
    };

    // Redirect to login if authentication is required and user is not authenticated
    if (requiresAuth && !isAuthenticated) {
        next('/Security/Login');
    } else if (requiresAuth && !isUserTypeAllowed()) {
        next('/Security/Login'); // Redirect to login if user type is not allowed
    } else {
        next(); // Proceed to the requested route
    }
});


axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        if (router.currentRoute.path !== '/Security/Login') {
            router.push('/Security/Login');
        }
    }
    return Promise.reject(error);
});

Vue.filter('toUpperCase', function (value) {
    if (!value) return '';
    return value.toUpperCase();
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
