<template>
  <div class="bootstrap-scope ">
  <div class="text-center" v-cloak>
    <h1 class="title" v-show="Loading">Loading</h1>
    <div class="rainbow-marker-loader" v-show="Loading"></div>
    <form class="form-signin" v-if="!twoFactorPrompt && !twoFactorSetup" v-show="!Loading">
      <img class="mb-4" src="/assets/img/logo.jpg" alt="" style="width:300px;height:90px;" height="auto" />
      <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input
        type="email"
        id="inputEmail"
        v-model="form.Email"
        class="form-control"
        placeholder="Email address"
        required
        autofocus
      />
      <br>
      <label for="inputPassword" class="sr-only">Password</label>
      <input
        type="password"
        id="inputPassword"
        v-model="form.Password"
        class="form-control"
        placeholder="Password"
        required
      />
      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me" /> Remember me
        </label>
      </div>
      <button id="sign-in-button" @click.prevent="login" class="btn btn-lg btn-primary btn-block">
        Sign in
      </button>
      <p class="mt-5 mb-5 text-muted">
        High National Election Commission &copy; {{ new Date().getFullYear() }}
      </p>
      <p>Version: {{ version }}</p>
    </form>

    <form class="form-signin" v-if="twoFactorPrompt" v-show="!Loading">
      <img class="mb-4" src="/assets/img/logo.jpg" alt="" style="width:300px;height:90px;" height="auto" />
      <h1 class="h3 mb-3 font-weight-normal">Please enter the 2FA code</h1>
      <div class="form-group">
        <label for="inputCode" class="sr-only">2FA Code</label>
        <input
          type="text"
          id="inputCode"
          v-model="twoFactorCode"
          class="form-control"
          placeholder="2FA code"
          required
          autofocus
        />
      </div>

      <button id="verify-2fa-button" @click.prevent="verifyTwoFactor" class="btn btn-lg btn-primary btn-block">
        Verify
      </button>
      <button id="back-button" @click.prevent="backToLogin" class="btn btn-lg btn-danger btn-block">
        Back
      </button>
      <p class="mt-5 mb-5 text-muted">
        High National Election Commission &copy; {{ new Date().getFullYear() }}
      </p>
    </form>

    <form class="form-signin" v-if="twoFactorSetup" v-show="!Loading">
      <img class="mb-4" src="/assets/img/logo.jpg" alt="" style="width:300px;height:90px;" height="auto" />
      <h1 class="h3 mb-3 font-weight-normal">Set up 2FA</h1>
      <div class="form-group">
        <p>Scan the QR code below with your 2FA app:</p>
        <img :src="qrCodeUrl" alt="QR Code" style="width:200px;height:200px;" height="auto" />
      </div>

      <div class="form-group">
        <label for="inputCodeSetup" class="sr-only">2FA Code</label>
        <input
          type="text"
          id="inputCodeSetup"
          v-model="twoFactorCode"
          class="form-control"
          placeholder="2FA code"
          required
          autofocus
        />
      </div>

      <button id="confirm-2fa-button" @click.prevent="verifyTwoFactor" class="btn btn-lg btn-primary btn-block">
        Confirm
      </button>
      <button id="back-button-setup" @click.prevent="backToLogin" class="btn btn-lg btn-danger btn-block">
        Back
      </button>
      <p class="mt-5 mb-5 text-muted">
        High National Election Commission &copy; {{ new Date().getFullYear() }}
      </p>
    </form>
  </div>
  </div>
</template>

<script>
  import swal from 'sweetalert2';


  export default {
    name: 'Login',
    created() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
  },
    mounted() {
    debugger; // eslint-disable-line
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
    document.head.appendChild(link);
  },
    data() {
      return {
        form: {
          Email: null,
          Password: null,
        },
        twoFactorCode: '',
        twoFactorPrompt: false,
        twoFactorSetup: false,
        Loading: false,
        qrCodeUrl: '',
        userEmail: '',
        version: '1.0.0',
      };
    },
    methods: {
      login() {
        debugger; // eslint-disable-line
        if (!this.form.Email || !this.form.Password) {
          swal.fire('خطأ', 'الرجاء إدخال البريد الإلكتروني وكلمة المرور.', 'error');
          return;
        }
        this.$blockUI.Start();
        debugger; // eslint-disable-line
        this.$http.loginUser(this.form)
          .then((response) => {
            debugger; // eslint-disable-line
            this.$blockUI.Stop();
            if (response && response.data) {
              if (response.data.requiresTwoFactor) {
                this.twoFactorPrompt = true;
                this.userEmail = response.data.email;
              } else if (response.data.enableTwoFactor) {
                this.twoFactorSetup = true;
                this.qrCodeUrl = response.data.qrCodeImageData;
                this.userEmail = response.data.email;
              } else {
                window.location.href = '/';
              }
        } else {
          swal.fire('Error', 'Unexpected response from the server.', 'error');
        }
            // if (response.data.requiresTwoFactor) {
            //   this.twoFactorPrompt = true;
            //   this.userEmail = response.data.email;
            // } else if (response.data.enableTwoFactor) {
            //   this.twoFactorSetup = true;
            //   this.qrCodeUrl = response.data.qrCodeImageData;
            //   this.userEmail = response.data.email;
            // } else {
            //   window.location.href = '/';
            // }
          })
          .catch((error) => {
            debugger; // eslint-disable-line
            this.$blockUI.Stop();
            swal.fire('Error', error.response.data, 'error');
          });
      },
      verifyTwoFactor() {
        if (!this.twoFactorCode) {
          swal.fire('Error', 'Please enter the 2FA code.', 'error');
          return;
        }
        this.$blockUI.Start();
        this.$http.verifyTwoFactor({ Email: this.userEmail, Code: this.twoFactorCode })
          .then((response) => {
            this.$blockUI.Stop();
            debugger; // eslint-disable-line
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data));
            window.location.href = '/';
          })
          .catch((error) => {
            this.$blockUI.Stop();
            swal.fire('Error', error.response.data, 'error');
          });
      },
      backToLogin() {
        this.twoFactorPrompt = false;
        this.twoFactorSetup = false;
        this.twoFactorCode = '';
        this.qrCodeUrl = '';
        this.userEmail = '';
      },
    },
  };
</script>

  
<style scoped>
  
  .bootstrap-scope .text-center {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top:150px;
      }
      
      .bootstrap-scope .title {
        font-size: 2rem;
      }

</style>
  