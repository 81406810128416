<!-- src/components/NotFound.vue -->
<template>
    <div class="not-found">
      <div class="not-found-content">
        <img src="/assets/img/logo.jpg" alt="Company Logo" class="logo">
        <h1>404</h1>
        <h2>الصفحة غير موجودة</h2>
        <p>عذرًا، الصفحة التي تبحث عنها غير موجودة أو تم نقلها.</p>
        <router-link to="/" class="home-link">الذهاب إلى الصفحة الرئيسية</router-link>
        <div class="company-name">المفوضية الوطنية العليا للإنتخابات</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  };
  </script>
  
  <style scoped>
  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    direction: rtl;
  }
  
  .not-found-content {
    text-align: center;
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
  }
  
  .logo {
    width: 200px;
    margin-bottom: 20px;
  }
  
  .not-found h1 {
    font-size: 6rem;
    margin: 0;
    color: #ff6f61;
  }
  
  .not-found h2 {
    font-size: 2rem;
    margin: 10px 0;
    color: #333;
  }
  
  .not-found p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #ff6f61;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .home-link:hover {
    background-color: #ff4c3b;
  }
  
  .company-name {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #333;
  }
  </style>
  