<template>
    <!-- router view -->
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            // data
        }
    },
    created() {
        // created
        this.$blockUI.$loading = this.$loading;
    },
    methods: {
        // methods
    }
}


</script>
<style>


</style>