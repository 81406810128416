import { Affix } from 'vue-affix';
//routes
import { routes } from '../../../routers/routes.js';
export default {
    name: 'appHeader',
    created() {
        window.scrollTo(0, 0);
        if (localStorage.getItem("Dashboard") != null) {
            var root = document.getElementsByTagName("body")[0]; // '0' to assign the first (and only `HTML` tag)
            root.setAttribute("class", localStorage.getItem("Dashboard"));
        }

        this.UserData = this.$store.getters.getUserData;
        this.userType = this.UserData.userType;

        this.CheckLoginStatus();
        //  this.userType = this.$store.getters.getUserData.userType
        // check if url equal /EndorsementsChecker
        if (this.$route.path == "/EndorsementsChecker") {
            this.$set(this.menuFlag, 35, 'active');
            this.collapseEndorsment = 'collapse show';
        }

    },
    components: {
        Affix,
    },

    data() {
        return {
            BaseURL: process.env.VUE_APP_BACKEND_URL,
            collapseEndorsment: 'collapse',
            active: 1,
            menuFlag: [60],
            userType: null,
            loginDetails: null,
            UserData: null,
        };
    },


    methods: {
        hasPermission(path) {
            const route = routes.find(r => r.path === path);
            if (!route || !route.meta || !route.meta.requiredUserType) {
                return true;
            }
            const userType = this.loginDetails.userType;
            const requiredUserType = route.meta.requiredUserType;
            if (Array.isArray(requiredUserType)) {
                return requiredUserType.includes(userType);
            }
            return userType === requiredUserType;
        },
        href(url, id) {
            for (var i = 0; i < 60; i++) {
                if (i == id) {
                    this.$set(this.menuFlag, id, 'active');
                } else {
                    this.$set(this.menuFlag, i, '');
                }
            }
            this.$router.push(url).catch(() => { });
        },

        CheckLoginStatus() {
            debugger; // eslint-disable-line
            try {
                const user = localStorage.getItem('user');
                const userobj = JSON.parse(user);
                this.loginDetails = userobj;
                if (this.loginDetails == null) {
                    window.location.href = '/Security/Login';
                }
            } catch (error) {
                window.location.href = '/Security/Login';
            }
        },

        OpenMenuByToggle() {
            var root = document.getElementsByTagName("body")[0]; // '0' to assign the first (and only `HTML` tag)
            if (root.getAttribute("class") == "rtl sidebar-mini rtl-active") {
                root.setAttribute("class", "rtl rtl-active");
                localStorage.setItem("Dashboard", "rtl rtl-active");
            } else {
                root.setAttribute("class", "rtl sidebar-mini rtl-active");
                localStorage.setItem("Dashboard", "rtl sidebar-mini rtl-active");
            }
        },
    }
}
